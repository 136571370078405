import { DropdownContainer } from "./language.style";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import i18n from "../../../utils/i18n";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { motion } from "framer-motion";
import Theme from "../../../utils/stylesTheme";
import { ReactComponent as ArabicLogo } from "../../../assets/icons/arabic.svg";
import { ReactComponent as EnglishLogo } from "../../../assets/icons/english.svg";

const Language = () => {
	const currentLanguage = i18n.language;
	const [language, setLanguage] = useState(
		currentLanguage === "ar" ? "العربية" : "English"
	);
	const [isLangOpen, setIsLangOpen] = useState(false);
	const queryClient = useQueryClient();
	const langDropdownRef = useRef<HTMLDivElement | null>(null); 

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
		  if (langDropdownRef.current && !langDropdownRef.current.contains(e.target as Node)) {
			setIsLangOpen(false);
		  }
		};
	
		document.addEventListener("click", handleClickOutside);
		return () => {
		  document.removeEventListener("click", handleClickOutside);
		};
	  }, []);

	return (
		<DropdownContainer ref={langDropdownRef}  className="dropdown">
			<button className="dropbtn1" onClick={() => setIsLangOpen(!isLangOpen)}>
				<LanguageOutlinedIcon />
				{language}
				<ExpandMoreOutlinedIcon />
			</button>
			<div
				className="dropdown-content flex flex-col"
				style={{ zIndex: "2000", display: isLangOpen ? "flex" : "none" }}
			>
				<motion.button
					whileHover={{ color: Theme.TextPrimary, scale: 1.1 }}
					className="w-full font-semibold text-xl p-2 flex items-center justify-between"
					onClick={() => {
						i18n.changeLanguage("ar");
						setLanguage("العربية");
						document.dir = "rtl";
						axios.defaults.headers.common["lang"] = "ar";
						setIsLangOpen(false);
					}}
				>
					<ArabicLogo style={{ width: "25px", height: "25px" }} />
					العربية
				</motion.button>
				<motion.button
					whileHover={{ color: Theme.TextPrimary, scale: 1.1 }}
					className="w-full font-semibold text-xl p-2 flex items-center justify-between"
					onClick={() => {
						i18n.changeLanguage("en");
						setLanguage("English");
						document.dir = "ltr";
						axios.defaults.headers.common["lang"] = "en";
						setIsLangOpen(false);
					}}
				>
					<EnglishLogo style={{ width: "25px", height: "25px" }} />
					English
				</motion.button>
			</div>

		</DropdownContainer>
	);
};

export default Language;
