import Logo from "../../../assets/wa3ya-invert.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { ReactComponent as VendorLogo } from "../../../assets/icons/become-verndor.svg";
import Language from "../language/language.component";
import { LogoContainer } from "./navbar-contact.style";
import Theme from "../../../utils/stylesTheme";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion, useAnimation } from "framer-motion";
import { Divider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchContact } from "../../../utils/api/contact";
import Loader from "../../global/loader/loader.component";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/store";
import { useEffect, useState } from "react";

export default function NavbarContact() {
  const control = useAnimation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (isScrolled) {
      control.start({ opacity: 0, height: 0 });
    } else {
      control.start({ opacity: 1, height: "auto" });
    }
  }, [isScrolled]);
  window.addEventListener("scroll", () => {
    if (window.scrollY < 100 && isScrolled) {
      setIsScrolled(false);
    } else if (window.scrollY > 200 && !isScrolled) {
      setIsScrolled(true);
    }
  });
  const { t } = useTranslation();
  const contacts = useQuery({
    queryKey: ["contacts"],
    queryFn: fetchContact,
  });
  const user = useAppSelector((state) => state.user.userData);
  if (contacts.isLoading) return <Loader />;
  if (contacts.isError) return <div>Error..</div>;
  const { email, phone } = contacts.data.data;
  return (
    <motion.div
      className={`flex flex-col md:flex-row justify-between items-center self-stretch py-2 bg-pink-100`}
      animate={control}
    >
      <div className="flex gap-10 items-start self-stretch my-auto text-base text-gray-800 min-w-[240px]">
        <div className="flex gap-10 items-center self-stretch my-auto min-w-[240px]">
          <motion.a
            initial={{ scale: 0.9 }}
            whileHover={{ scale: 1, color: Theme.TextPrimary }}
            href={`mailto:${email}`}
            className="flex items-center gap-2 px-2 font-semibold"
          >
            <EmailOutlinedIcon />
            {email}
          </motion.a>
          <motion.a
            initial={{ scale: 0.9 }}
            whileHover={{ scale: 1, color: Theme.TextPrimary }}
            href={`tel:${phone}`}
            className="flex items-center gap-2 px-2 font-semibold"
          >
            <PhoneOutlinedIcon />
            {phone}
          </motion.a>
        </div>
      </div>
      <div className="flex flex-row-reverse px-4 gap-4 my-auto text-sm font-medium leading-none text-white md:min-w-[240px] md:w-[556px] max-md:max-w-full">
        <motion.a
          initial={{ scale: 0.9 }}
          href="/contact-us"
          className="overflow-hidden gap-2 self-stretch px-5 py-2.5 my-auto bg-pink-500 rounded-lg"
        >
          {t("Contact Us")}
        </motion.a>
      </div>
    </motion.div>
  );
}
