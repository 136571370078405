import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Theme from "../../../utils/stylesTheme";
import Dropdown from "../dropdown/dropdown.component";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import SideMenu from "../side-menu/side-menu.component";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchCategories } from "../../../utils/api/categories";
import SearchBox from "../search-box/search-box.component";

const links = [
	{
		title: "Home",
		to: "/home",
	},
	{
		title: "About",
		to: "/about-us",
	},
	{
		title: "Shop",
		to: "/products",
	},
	
];
export default function NavigationLinks() {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const currentPath = "/" + useLocation().pathname.split("/")[1];
	const categories = useQuery({
		queryKey: ["categories"],
		queryFn: fetchCategories,
	  });
	return (
		<div className="flex flex-row justify-between gap-5 items-start">
		<ul className="hidden xl:flex justify-between font-semibold gap-5 text-lg">
			{links?.map(({ title, to }) => (
				
				<motion.li
					whileHover={{ scale: 1.1, color: Theme.TextPrimary }}
					key={to}
				>
					{currentPath === to ? (
						<Link
							className="font-bold"
							style={{
								color:
									currentPath === to ? Theme.TextPrimary : Theme.TextSecondary,
							}}
							to={to}
						>
							{t(title)}
						</Link>
					) : (
						<Link to={to}>{t(title)}</Link>
					)}
					
				</motion.li>
				
			))}
		</ul>
		<div className="flex md:hidden">
			<SearchBox />
		</div>
		<button
        onClick={() => setOpen(!open)}
        className="flex xl:hidden dropbtn items-center justify-between gap-4 rounded-xl p-3 font-bold"
        style={{
          background: Theme.TextPrimary,
          color: Theme.BackgroundPrimary,
        }}
      >        <FormatListBulletedOutlinedIcon />
      </button>
	  <SideMenu
        categories={categories?.data?.data}
        isOpen={open}
        setIsOpen={setOpen}
      />
		</div>
	);
}
