import NavbarContact from "./navbar-contact/navbar-contact.component";
import NavbarNavigations from "./navbar-navigations/navbar-navigations.component";
import { motion } from "framer-motion";
import ChatBubble from "../chatBubble/chatBubble";
import SignUpBanner from "./sign-up-banner/sign-up-banner.component";


export default function Navbar() {
  return (
    <>
      <motion.div className="flex flex-col items-center w-full relative">
        <SignUpBanner />
        <NavbarContact />
        <NavbarNavigations />
        <ChatBubble />
      </motion.div>
    </>
  );
}
