import { useAppSelector } from "../../../store/store";
import { Link } from "react-router-dom";
import Theme from "../../../utils/stylesTheme";
import { useTranslation } from "react-i18next";
import {
	Drawer,
} from "@mui/material";
import { motion } from "framer-motion";
import i18n from "../../../utils/i18n";
import Language from "../language/language.component";
import { useQuery } from "@tanstack/react-query";
import { fetchCart } from "../../../utils/api/cart";
import { LogoContainer } from "../navbar-contact/navbar-contact.style";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../../../assets/wa3ya-invert.png";

const links = [
	{
		title: "About",
		to: "/about-us",
		for: "all",
	},
	{
		title: "Shop",
		to: "/products",
		for: "all",
	},
	{
		title: "Login",
		to: "/login",
		for: "nonusers"
	},
	{
		title: "Profile",
		to: "/profile",
		for: "user"
	},
	{
		title: "Notifications",
		to: "/profile/notifications",
		for: "user"
	},
	{
		title: "Favorites",
		to: "/profile/wishlist",
		for: "user"
	},
	{
		title: "Cart",
		to: "/cart",
		for: "user"
	},

];

interface Iprops {
	categories: { title: string; id: number }[];
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}
export default function SideMenu({ categories, isOpen, setIsOpen }: Iprops) {
	const toggleDrawer = (newOpen: boolean) => () => {
		setIsOpen(newOpen);
	};

	const direction = i18n.language === "ar" ? "right" : "left";
	const { t } = useTranslation();
	const currentUser = useAppSelector((state) => state.user.userData);

	const cartItems = useQuery({
		queryKey: ["cartItems"],
		queryFn: () =>
			fetchCart(currentUser),
	});

	return (
		<Drawer
			sx={{ backgroundColor: "transparent" }}
			open={isOpen}
			anchor={direction}
			onClose={toggleDrawer(false)}
		>
			
			<div
				className="h-full flex flex-col justify-start overflow-y-auto overflow-hidden px-3"
				style={{
					backgroundColor: Theme.BackgroundPrimary,
				}}
			>
							<LogoContainer to="/home">
					<motion.div
						whileHover={{ scale: 1.1 }}
						className="w-20 md:w-32 flex items-center py-5"
					>
						<LazyLoadImage
							className="max-w-full max-h-full w-full"
							src={Logo}
							width={200}
							height={100}
							alt="logo"
						/>
					</motion.div>
			</LogoContainer>
<Language />
				<div className="flex flex-col items-center justify-start">
					<motion.div
						className="flex w-full"
						whileHover={{ scale: 1.2, color: Theme.TextPrimary }}
					>
						<Link
							className="h-full w-full py-4 "
							to="/home"
						>
							{t("Home")}
						</Link>
					</motion.div>
					{links?.map((link) => (
						(link.for == "all" || (link.for == "user" && currentUser) || (link.for == "nonusers" && !currentUser) ) &&(
						<motion.div
							key={link.title}
							className="flex w-full"
							whileHover={{ scale: 1.2, color: Theme.TextPrimary }}
						>
							<Link
								className="h-full w-full py-4"
								to={link.to}
							>
								{t(link.title) === "cart" ? <>
								{t('Cart')} {"   "}
								<span className="self-stretch px-2 my-auto w-8 h-8 text-xs text-center bg-red-600 min-h-[32px] rounded-[1000px] text-neutral-50">{cartItems?.data?.product?.length}</span> </>: t(link.title)}
							</Link>
						</motion.div>
					)))}		
				</div>
			</div>
		</Drawer>
	);
}
