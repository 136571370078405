import { IconButton } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "react-i18next";
import Theme from "../../../utils/stylesTheme";
import { motion } from "framer-motion";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setSearch } from "../../../store/search/searchSlice";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

export default function SearchBox() {
	const { t } = useTranslation();
	const location = useLocation();
	let searchType: string;
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState("");

	if (location.pathname.split("/")[1].includes("product"))
		searchType = "product";
	else if (location.pathname.split("/")[1].includes("device"))
		searchType = "device";
	else if (location.pathname.split("/")[1].includes("team"))
		searchType = "team";
	else searchType = "";

	return (
		<motion.form
			whileFocus={{ borderWidth: "2px" }}
			className="flex rounded-full px-4 py-3 items-center"
			style={{ border: `1px solid ${Theme.TextPrimary}` }}
			onSubmit={(e) => {
				e.preventDefault();
				if (!Boolean(searchValue)) {
					navigate("/products");
					return;
				}
				const searchObject = JSON.stringify({
					searchValue,
					type: searchType,
				});
				if (!Boolean(searchType)) navigate("/products/" + searchObject);
				else navigate(`/${searchType}s/${searchObject}`);
			}}
		>
			<input
				className="w-full px-4"
				style={{
					caretColor: Theme.TextPrimary,
				}}
				placeholder={t("Search your product")}
				onChange={(e) => setSearchValue(e.target.value)}
			/>
			<IconButton
				// onClick={() => {
				// 	// queryCleint.invalidateQueries();
				// 	// dispatch(setSearch({ value: searchValue, type: searchType }));
				// }}
				type="submit"
				sx={{
					width: 4,
					height: 4,
					":hover": {
						color: Theme.TextPrimary,
					},
				}}
			>
				<SearchOutlinedIcon />
			</IconButton>
		</motion.form>
	);
}
