
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchTopBannerContent } from "../../../utils/api/topBannerContent";
import Loader from "../../loader/loader.component";
import i18n from "../../../utils/i18n";

export default function SignUpBanner() {
	const { t } = useTranslation();
	const [show, setShow] = useState(true);
	const currentLanguage = i18n.language;

	const fetchTopBannerContentData = useQuery({
		queryKey: ["wishlist"],
		queryFn: () => fetchTopBannerContent(),
	});
	if (fetchTopBannerContentData.isLoading) return <Loader />;
	if (fetchTopBannerContentData.isError) return <div>Error..</div>;

	const topBannerContent = currentLanguage == 'ar' ? fetchTopBannerContentData?.data[0]?.title_ar 
	: fetchTopBannerContentData?.data[0]?.title_en;

	return (
		<>
		
		{(show) &&
		<div className="flex flex-wrap gap-3 justify-center items-center self-stretch px-4 py-1 text-sm leading-none text-white bg-gray-900">
		 <span dangerouslySetInnerHTML={{ __html: topBannerContent }}></span>
		<CloseIcon className="cursor-pointer" onClick={() => {setShow(false)}}></CloseIcon>
	  </div>}
	  </>
	);
}
