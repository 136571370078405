import { type IcardData } from "../recomendation-card/recomendation-card.component";
import { Carousel } from "flowbite-react";
import banner2 from "../../../assets/images/Banners/banners-2.png";
import { Link } from "react-router-dom";

interface SuggestionsContainerProps {
  suggestionsData: {
    banner_ads: IcardData[];
  };
}
export default function SuggestionsContainer({
  suggestionsData,
}: SuggestionsContainerProps) {
  const { banner_ads } = suggestionsData;
  return (
    <div className="h-80 mt-10 w-full">
      <Carousel>
        {banner_ads?.map((cardData, index) => (
			<Link key={index} to={"/product/"+ cardData.product_id}>
          <div
		  key={index}
            className="h-full min-h-[300px] flex flex-row md:gap-10 gap-3"
            style={{
              backgroundImage: `url(${banner2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img
              src={cardData.main_image}
              alt={cardData.small_text}
              className="min-h-full w-1/2 md:w-80 md:ml-[100px]"
            />
            <div className="flex flex-col grow shrink self-stretch my-auto md:min-w-[240px] md:w-[710px] max-md:max-w-full">
              <h1 className="text-2xl md:text-3xl font-semibold leading-tight text-gray-800 max-md:max-w-full">
                {cardData.small_text}
              </h1>
              <div className="flex flex-wrap gap-2.5 items-center mt-4 w-full font-medium max-md:max-w-full">
                <div className="self-stretch my-auto text-4xl font-bold leading-none text-gray-900">
                  {cardData.real_price}
                </div>
                <div className="self-stretch my-auto text-xl leading-snug text-gray-800 opacity-50">
                  <span className="line-through">{cardData.fake_price}</span>
                </div>
                <div className="self-stretch px-2.5 py-0.5 my-auto text-lg leading-loose text-center text-white whitespace-nowrap bg-red-600 rounded-md">
                  -{Math.floor(parseInt(cardData.discount))}%
                </div>
              </div>
            </div>
          </div>
		  </Link>
        ))}
      </Carousel>
    </div>
  );
}
