import axios from "axios";
import END_POINTS from "./api";

export const fetchCart = async (additionPrams = {}) => {
	if(additionPrams == null){
		return {};
	}
	const response = await axios.get(END_POINTS.Cart, { params: additionPrams });
	return response.data.data;
};
export interface IaddToCart {
	product_id: number;
	quantity: number;
}
export const addToCart = async (addData: IaddToCart) => {
	const response = await axios.post(END_POINTS.AddToCart, addData);
	return response.data;
};
export const RemoveFromCart = async (cart_id: number) => {
	const response = await axios.post(END_POINTS.RemoveFromCart, { cart_id });
	return response.data;
};
export interface IupdateCartItem {
	cart_id: number;
	quantity: number;
}
export const updateCartItem = async (itemData: IupdateCartItem) => {
	const response = await axios.post(END_POINTS.UpdateCartItem, itemData);

	return response.data;
};
export const emptyCart = async () => {
	const response = await axios.post(END_POINTS.EmptyCart);
	return response.data;
};
export const checkPromoCode = async (promoCode: string) => {
	const response = await axios.post(END_POINTS.CheckPromoCode, {
		coupon: promoCode,
	});
	return response.data;
};
