import Dropdown from "../dropdown/dropdown.component";
import NavigationLinks from "../navigation-links/navigation-links.component";
import SearchBox from "../search-box/search-box.component";
import ListIcon from "../../../assets/icons/list.svg";
import Notification from "../notification/notification.component";
import { useAppSelector } from "../../../store/store";
import Button from "../../global/button/button.component";
import { Link, useNavigate } from "react-router-dom";
import Theme from "../../../utils/stylesTheme";
import { useTranslation } from "react-i18next";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { IconButton } from "@mui/material";
import { LogoContainer } from "../navbar-contact/navbar-contact.style";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../../../assets/wa3ya-invert.png";
import Language from "../language/language.component";
import { DropdownContainer } from "../language/language.style";

export default function NavbarNavigations() {
	const currentUser = useAppSelector((state) => state.user.userData);
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<div className="flex justify-between w-screen md:w-full px-3 gap-5 items-center py-3">
			<LogoContainer to="/home">
					<motion.div
						whileHover={{ scale: 1.1 }}
						className="w-20 md:w-32 flex items-center"
					>
						<LazyLoadImage
							className="max-w-full max-h-full w-full"
							src={Logo}
							width={200}
							height={100}
							alt="logo"
						/>
					</motion.div>
			</LogoContainer>
			<NavigationLinks />
			
			<div className="hidden md:flex">
				<SearchBox />
				<Language />
			{currentUser ? (
				<Notification />
			) : (
				<div className="flex items-center justify-between flex-wrap gap-2">
					<Link to="/login">
						<PersonOutlineOutlinedIcon
							sx={{
								width: "30px",
								height: "30px",
								color: Theme.TextPrimary,
							}}
						/>
					</Link>
				</div>
			)}
			</div>
		</div>
	);
}
