import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { NotificationContainer } from "./notification.style";
import { Badge, Divider } from "@mui/material";
import Theme from "../../../utils/stylesTheme";
import NavMenu from "../nav-menu/nav-menu.component";
import { useState } from "react";
import { IconButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchProfile } from "../../../utils/api/profile";
import { useAppSelector } from "../../../store/store";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Loader from "../../global/loader/loader.component";
import { fetchCart } from "../../../utils/api/cart";
import { useTranslation } from "react-i18next";
import { fetchNotifications } from "../../../utils/api/notifications";

export default function Notification() {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const [tabNumber, setTabNumber] = useState(0);
	const userData = useAppSelector((state) => state.user.userData);
	const { promoCodeId, addressId } = useAppSelector((state) => state.cart.cart);
	const cartItems = useQuery({
		queryKey: ["cartItems"],
		queryFn: () =>
			fetchCart({ user_address_id: addressId, promo_code_id: promoCodeId }),
	});
	const notificationsData = useQuery({
		queryKey: ["notifications"],
		queryFn: () => fetchNotifications(),
	});
	if (userData.isLoading || cartItems.isLoading || notificationsData.isLoading)
		return <Loader />;
	if (userData.isError || cartItems.isError || notificationsData.isError)
		return <div>Error...</div>;

	return (
		<NotificationContainer className="flex items-start max-h-5 gap-4 pr-4">
			<motion.div whileHover={{ scale: 1.1 }}>
			<Link to="/profile/notifications">
				<Badge
					badgeContent={notificationsData.data.data.length}
					sx={{
						".MuiBadge-badge": {
							backgroundColor: Theme.TextPrimary,
							color: Theme.BackgroundPrimary,
							fontWeight: "bold",
						},
					}}
					onClick={() => {
						setShow(true);
						setTabNumber(0);
					}}
				>
					<NotificationsNoneOutlinedIcon
						sx={{
							width: "30px",
							height: "30px",
							color: Theme.TextPrimary,
							cursor: "pointer",
						}}
					/>
				</Badge>
				</Link>
			</motion.div>
			<motion.div whileHover={{ scale: 1.1 }}>
				<Link to="/cart">
				<Badge
					onClick={() => {
						setShow(true);
						setTabNumber(1);
					}}
					badgeContent={cartItems.data.product.length}
					sx={{
						".MuiBadge-badge": {
							backgroundColor: Theme.TextPrimary,
							color: Theme.BackgroundPrimary,
							fontWeight: "bold",
						},
					}}
				>
					<ShoppingCartOutlinedIcon
						sx={{
							width: "30px",
							height: "30px",
							color: Theme.TextPrimary,
							cursor: "pointer",
						}}
						color="action"
					/>
				</Badge>
				</Link>
			</motion.div>
			<motion.div
				whileHover={{ scale: 1.1 }}
				className="flex gap-2 cursor-pointer"
				onClick={() => {
					setShow(true);
					setTabNumber(2);
				}}
			>
				<Link to="/profile">
				<Badge
					badgeContent={0}
					sx={{
						".MuiBadge-badge": {
							backgroundColor: Theme.TextPrimary,
							color: Theme.BackgroundPrimary,
							fontWeight: "bold",
						},
					}}
				>
					<PersonOutlineOutlinedIcon
						sx={{
							width: "30px",
							height: "30px",
							color: Theme.TextPrimary,
						}}
					/>
				</Badge>
				</Link>
				<span className="hidden 2xl:block">
					{t("Hello")}, {userData.fname}
				</span>
			</motion.div>
		</NotificationContainer>
	);
}
